import { LoggedInPermissions, UserPermissions } from 'actff-bo-lib/user'

import { ComponentType, IconType, Path } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const clientMenu = {
  access: LoggedInPermissions,
  component: ComponentType.ClientList,
  expanded: false,
  icon: IconType.Clients,
  name: 'menu.clients',
  path: Paths.Client as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      childrenHasMultipleTabs: true,
      component: ComponentType.ClientList,
      expanded: true,
      name: 'menu.clients.userList',
      path: Paths.ClientList as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewBasicData,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.ClientViewBasicData as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientNew,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.ClientNew as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewCars,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.ClientViewCars as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewHistory,
          expanded: false,
          path: Paths.ClientViewHistory as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewApprovals,
          expanded: false,
          path: Paths.ClientViewApprovals as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewInvoices,
          expanded: false,
          path: Paths.ClientViewInvoices as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewPreferences,
          expanded: false,
          path: Paths.ClientViewPreferences as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.ClientViewOpportunities,
          expanded: false,
          path: Paths.ClientViewOpportunities as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CarList,
      expanded: false,
      name: 'menu.clients.carList',
      path: Paths.CarList as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CarViewBasicData,
          expanded: false,
          path: Paths.CarViewBasicData as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [
            UserPermissions.ServiceViewService,
            UserPermissions.ServiceEditService,
            UserPermissions.FinanceEditService,
            UserPermissions.InsuranceEditService,
            UserPermissions.AdminAllService,
          ],
          component: ComponentType.CarNew,
          expanded: false,
          path: Paths.CarNew as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [
            UserPermissions.ServiceViewService,
            UserPermissions.ServiceEditService,
            UserPermissions.FinanceViewService,
            UserPermissions.InsuranceViewService,
            UserPermissions.AdminAllService,
          ],
          component: ComponentType.CarViewService,
          expanded: false,
          path: Paths.CarViewService as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [
            UserPermissions.InsuranceViewInsurance,
            UserPermissions.InsuranceEditInsurance,
            UserPermissions.FinanceViewInsurance,
            UserPermissions.ServiceViewInsurance,
            UserPermissions.AdminAllService,
          ],
          component: ComponentType.CarViewInsurance,
          expanded: false,
          path: Paths.CarViewInsurance as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [
            UserPermissions.FinanceViewFinance,
            UserPermissions.FinanceEditFinance,
            UserPermissions.InsuranceViewFinance,
            UserPermissions.ServiceViewFinance,
            UserPermissions.AdminAllService,
          ],
          component: ComponentType.CarViewFinance,
          expanded: false,
          path: Paths.CarViewFinances as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [
            UserPermissions.ServiceViewService,
            UserPermissions.ServiceEditService,
            UserPermissions.FinanceEditService,
            UserPermissions.InsuranceEditService,
            UserPermissions.AdminAllService,
          ],
          component: ComponentType.CarViewTires,
          expanded: false,
          path: Paths.CarViewTires as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.CarViewEquipment,
          expanded: false,
          path: Paths.CarViewEquipment as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.CarViewInspections,
          expanded: false,
          path: Paths.CarViewInspections as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.CarViewInspection,
          expanded: false,
          path: Paths.CarViewNewInspection as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.CarViewInspection,
          expanded: false,
          path: Paths.CarViewInspection as Path,
          hiddenInMenu: true,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
  ],
}
